// vendors
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// components
import Layout from '@components/Layout';
import styled from 'styled-components';

// views
import SEO from '@src/components/SEO';
import HeroSection from '@src/views/AboutPageView/HeroSection';
import ContentSection from '@src/views/AboutPageView/ContentSection';

const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

const ProtectionRenseignementsPrivesPage = () => {
  const langLinks = [
    { langKey: 'en', href: '/en/protection-private-information' },
  ];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Protection des renseignements privés et nom du responsable'
        description='Permafil inc. a pris les dispositions pour protéger les renseignements personnels de ses employés, collaborateurs, fournisseurs et utilisateurs de son site Web conformément à la Loi 25.'
      />

      <HeroSection>
        <h1>Protection des renseignements privés</h1>
      </HeroSection>

      <ContentSection>
        <p>
          <Link to='/a-propos'>PERMAFIL INC.</Link> a pris les dispositions pour
          protéger les renseignements personnels de ses employés,
          collaborateurs, fournisseurs et utilisateurs de son site Web
          conformément à la Loi 25,{' '}
          <span
            css={`
              font-style: italic;
            `}
          >
            Loi sur la protection des Renseignements personnels dans le secteur
            privé
          </span>
          .
        </p>

        <p>
          À cet effet, un inventaire des renseignements sensibles a été fait et,{' '}
          <Link to='/a-propos'>PERMAFIL INC.</Link> a nommé une personne
          responsable de la protection des renseignements personnels afin
          qu’aucun préjudice ne soit causé par la divulgation de ces
          renseignements sans l’autorisation préalable de la personne concernée.
        </p>

        <p>
          Cette personne s’assure que toutes les informations sont détruites
          adéquatement dans les délais prescrits par la loi, que les personnes
          ayant accès à ces informations ont signé des ententes de
          confidentialité, que le nombre de ces personnes est restreint et que
          celles-ci sont au fait de la Loi 25 sur la protection des
          renseignements privés et qu’elles agiront en conséquence. Les filières
          contenant ces renseignements sont sous clés et électroniquement, les
          renseignements de l’entreprise sont protégés par des pares-feux et
          antivirus.
        </p>

        <p>La personne responsable est: Francis Levesque</p>

        <p>
          Coordonnées:{' '}
          <a href='mailto:controleur@permafil.com' itemProp='email'>
            controleur@permafil.com
          </a>
        </p>
      </ContentSection>
    </Layout>
  );
};

export default ProtectionRenseignementsPrivesPage;
